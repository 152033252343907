













import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import { BoolFilterModel, FilterPreviewId } from "@/shared/models";

@Component({ components: { CustomSelect } })
export default class BoolFilter extends Vue {
  @Prop() value!: BoolFilterModel;
  @Prop() label!: string;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: true }) validate!: boolean;
  @Prop({ default: false }) isAttached!: boolean;
  @Prop() filterPreviewId?: FilterPreviewId;
  @Prop() itemsFilter?: (item: any, currentValue: any) => boolean;

  localFilter: BoolFilterModel = BoolFilterModel.of(this.value);

  get items() {
    return [
      {
        value: null,
        text: this.$lang(`shared.boolNames.any`),
      },
      {
        value: true,
        text: this.$lang(`shared.boolNames.on`),
      },
      {
        value: false,
        text: this.$lang(`shared.boolNames.off`),
      },
    ].filter((item) => !this.itemsFilter || this.itemsFilter(item, this.value));
  }

  @Watch("value", { immediate: true })
  private watchValue(value: BoolFilterModel) {
    this.localFilter = BoolFilterModel.of(value);
  }

  @Watch("localFilter", { deep: true })
  private watchLocalFilter(localFilter: BoolFilterModel) {
    const filter = new BoolFilterModel(
      this.value.id,
      this.value.required,
      this.value.filled,
      this.value.valid,
      localFilter.value
    );

    if (!this.readonly) {
      this.$emit("input", Object.assign(this.value, filter));
    }
  }
}
