

















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import EventRangePicker from "@/shared/components/pickers/EventRangePicker.vue";
import {
  DictionaryType,
  ValidationRule,
  FilterPreviewId,
  EventParamFilterRangeModel,
  EventRangePickerModel,
  EventRangeModel,
  RangeModel,
  EventParamRangeModel,
  EventPickerParamRangeModel,
} from "@/shared/models";

@Component({
  components: {
    EventRangePicker,
  },
})
export default class EventParamFilter extends Vue {
  @Prop() value!: EventParamFilterRangeModel;
  @Prop() applicationId!: string;
  @Prop() label!: string;
  @Prop() dataTypes!: Array<DictionaryType>;
  @Prop({ default: true }) multipleParams!: boolean;
  @Prop({ default: false }) excludable!: boolean;
  @Prop({ default: true }) loadData!: boolean;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: true }) validate!: boolean;
  @Prop() rules?: Array<ValidationRule>;
  @Prop({ default: () => [] }) errorMessages!: Array<string>;
  @Prop({ default: false }) autofocus!: boolean;
  @Prop({ default: false }) isAttached!: boolean;
  @Prop({ default: true }) validation!: boolean;
  @Prop() filterPreviewId?: FilterPreviewId;
  @Prop() itemsFilter?: (item: any, currentValue: any) => boolean;

  localValueEvent: EventRangePickerModel = new EventRangePickerModel();

  get valid(): boolean {
    return (
      !!this.localValueEvent.name &&
      (!this.localValueEvent.params ||
        this.localValueEvent.params.every(
          ({ param, value }) =>
            !param ||
            (!!value?.operator &&
              value.params.length > 0 &&
              (value.params as Array<string | number>).every((it) => it !== ""))
        ))
    );
  }

  get localValue(): EventParamFilterRangeModel {
    return this.value;
  }

  @Watch("localValueEvent")
  watchLocalValueEvent(value: EventRangePickerModel) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.value = new EventRangeModel(
      value.included,
      value.name,
      value.value
        ? new RangeModel(value.value.operator, value.value.params)
        : undefined,
      value.params
        .filter((param) => !!param.param)
        .map((param) => new EventParamRangeModel(param.param, param.value))
    );

    this.$emit(
      "input",
      Object.assign(clonedLocalValue, {
        valid: this.validate ? this.valid : clonedLocalValue.valid,
      })
    );
  }

  created() {
    const params = this.localValue.value.params?.map(
      (param) => new EventPickerParamRangeModel(param.value, param.name)
    );

    this.localValueEvent = new EventRangePickerModel(
      this.localValue.value.included,
      params,
      this.localValue.value.name
    );

    if (!this.loadData) {
      return;
    }

    const dictionaryTypesToLoad = this.dataTypes.filter(
      (type) => this.$store.state.dictionaryStore[type].unfilled
    );

    if (dictionaryTypesToLoad.length) {
      this.$store.dispatch("loadDictionaries", {
        app: this.applicationId,
        dictionaryTypes: dictionaryTypesToLoad,
      });
    }
  }
}
