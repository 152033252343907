




















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import EventRangePicker from "@/shared/components/pickers/EventRangePicker.vue";
import {
  DictionaryType,
  ValidationRule,
  FilterPreviewId,
  EventParamDateFilterModel,
  EventParamRangeModel,
  EventRangePickerModel,
  EventRangeModel,
  RangeModel,
  EventPickerParamRangeModel,
} from "@/shared/models";

@Component({
  components: {
    EventRangePicker,
  },
})
export default class EventParamDateFilter extends Vue {
  @Prop() value!: EventParamDateFilterModel;
  @Prop() applicationId!: string;
  @Prop() label!: string;
  @Prop() dataTypes!: Array<DictionaryType>;
  @Prop({ default: true }) multipleParams!: boolean;
  @Prop({ default: false }) excludable!: boolean;
  @Prop({ default: true }) loadData!: boolean;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: true }) validate!: boolean;
  @Prop() rules?: Array<ValidationRule>;
  @Prop({ default: () => [] }) errorMessages!: Array<string>;
  @Prop({ default: false }) autofocus!: boolean;
  @Prop({ default: false }) isAttached!: boolean;
  @Prop() filterPreviewId?: FilterPreviewId;
  @Prop() itemsFilter?: (item: any, currentValue: any) => boolean;

  menuFromVisible = false;
  menuToVisible = false;
  localValueEvent: EventRangePickerModel = new EventRangePickerModel();

  get localValue(): EventParamDateFilterModel {
    return this.value;
  }

  get localValueFrom(): string | Date | undefined {
    return this.localValue?.from;
  }

  set localValueFrom(value: string | Date | undefined) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.from = value;
    this.$emit("input", clonedLocalValue);
  }

  @Watch("localValueEvent")
  watchL(value: EventRangePickerModel) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.value = new EventRangeModel(
      value.included,
      value.name,
      value.value
        ? new RangeModel(value.value.operator, value.value.params)
        : undefined,
      value.params
        .filter((param) => !!param.param)
        .map((param) => new EventParamRangeModel(param.param, param.value))
    );
    this.$emit("input", clonedLocalValue);
  }

  created() {
    const params = this.localValue.value.params?.map(
      (param) => new EventPickerParamRangeModel(param.value, param.name)
    );

    this.localValueEvent = new EventRangePickerModel(
      true,
      params,
      this.localValue.value.name
    );

    if (!this.loadData) {
      return;
    }

    const dictionaryTypesToLoad = this.dataTypes.filter(
      (type) => this.$store.state.dictionaryStore[type].unfilled
    );

    if (dictionaryTypesToLoad.length) {
      this.$store.dispatch("loadDictionaries", {
        app: this.applicationId,
        dictionaryTypes: dictionaryTypesToLoad,
      });
    }
  }
}
