


























import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import PickerDictionaryNotification from "@/shared/components/pickers/PickerDictionaryNotification.vue";

import {
  MultipleValueFilterModel,
  Dictionary,
  DictionaryType,
  ValidationRule,
  FilterPreviewId,
} from "@/shared/models";
import DictionaryLoadMixin from "@/shared/mixins/DictionaryLoadMixin";

@Component({
  components: {
    CustomAutocomplete,
    PickerDictionaryNotification,
  },
})
export default class MultipleValueFilter extends mixins(DictionaryLoadMixin) {
  @Prop() value!: MultipleValueFilterModel;
  @Prop() label!: string;
  @Prop() dataTypes!: Array<DictionaryType>;
  @Prop({ default: true }) loadData!: boolean;
  @Prop({ default: false }) readonly!: boolean;
  @Prop() rules?: Array<ValidationRule>;
  @Prop({ default: () => [] }) errorMessages!: Array<string>;
  @Prop({ default: false }) isAttached!: boolean;
  @Prop() filterPreviewId!: FilterPreviewId;
  @Prop() itemsFilter?: (item: any, currentValue: any) => boolean;

  localFilter: MultipleValueFilterModel = MultipleValueFilterModel.of(
    this.value
  );
  dictionaryType: DictionaryType = this.dataTypes[0];

  get items(): Array<Dictionary> {
    return this.dictionaryData.filter(
      (item: Dictionary) =>
        !this.itemsFilter || this.itemsFilter(item, this.value)
    );
  }

  get valid(): boolean {
    return !!this.localFilter.values?.length;
  }

  @Watch("value", { immediate: true })
  watchValue(value: MultipleValueFilterModel) {
    this.localFilter = MultipleValueFilterModel.of(value);

    const availableValues = this.items.map(({ value }) => value);

    this.localFilter.values = this.localFilter.values.filter((item) =>
      availableValues.includes(item)
    );
  }

  @Watch("localFilter", { deep: true })
  watchLocalFilter(localFilter: MultipleValueFilterModel) {
    if (this.readonly) {
      return;
    }

    this.$emit("input", Object.assign(this.value, localFilter));
  }

  @Watch("valid", { immediate: true })
  watchValid(valid: boolean) {
    this.localFilter.valid = valid;
  }

  @Watch("dictionaryData", { immediate: true })
  watchDictionaryData() {
    if (
      !this.localFilter.filled ||
      this.readonly ||
      this.localFilter.values.length
    ) {
      return;
    }

    this.localFilter.values = this.dictionaryData.map(({ value }) => value);
  }

  @Watch("localFilter.values")
  watchValues(newValues: Array<string>, oldValues: Array<string> | undefined) {
    this.setUsedDictionaries(newValues, oldValues);
  }

  created() {
    if (!this.dictionaryData.length) {
      this.$store.dispatch("loadDictionaries", {
        app: this.applicationId,
        dictionaryTypes: [this.dictionaryType],
      });
    }
  }
}
