


























import { Component, Vue, Prop } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import RangeFilter from "./RangeFilter.vue";
import CustomSelect from "@/shared/components/pickers/CustomSelect.vue";
import {
  Dictionary,
  DictionaryType,
  EventOperation,
  IapRevenueFilterModel,
  RangeModel,
} from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    CustomSelect,
    RangeFilter,
  },
})
export default class IapRevenueFilter extends Vue {
  @Prop() value!: IapRevenueFilterModel;
  @Prop({ default: "" }) applicationId!: string;
  @Prop({ default: "" }) label!: string;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) autofocus!: boolean;
  @Prop() dataTypes!: Array<DictionaryType>;

  readonly excludedOperations = [
    EventOperation.LIKE,
    EventOperation.NOT_LIKE,
    EventOperation.IN,
    EventOperation.NOT_IN,
  ];
  readonly rules = [ValidUtil.required(this.$lang("validation.required"))];

  dataType: DictionaryType = this.dataTypes[0];

  get localValue(): IapRevenueFilterModel {
    return this.value;
  }

  get localValueRevenueType(): Array<string> {
    return this.localValue.revenue;
  }

  set localValueRevenueType(value: Array<string>) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.revenue = value;
    this.$emit("input", clonedLocalValue);
  }

  get localValueRange(): RangeModel {
    return this.localValue.range;
  }

  set localValueRange(value: RangeModel) {
    const clonedLocalValue = cloneDeep(this.localValue);

    clonedLocalValue.range = value;
    this.$emit("input", clonedLocalValue);
  }

  get revenueTypes(): Array<Dictionary> {
    return this.$store.state.dictionaryStore[
      DictionaryType.REVENUES
    ].values.reduce(
      (result: Array<Dictionary>, { name, value }: Dictionary) => {
        if (["iaps", "subscription"].includes(value)) {
          result.push({
            text: name,
            value: value.toUpperCase(),
          });
        }

        return result;
      },
      []
    );
  }

  created() {
    if (
      this.dataType &&
      this.$store.state.dictionaryStore[this.dataType].unfilled
    ) {
      this.$store.dispatch("loadDictionaries", {
        app: this.applicationId,
        dictionaryTypes: [this.dataType],
      });
    }
  }
}
