












































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import MultipleValueFilter from "./MultipleValueFilter.vue";
import AttributionDateFilter from "./AttributionDateFilter.vue";
import ExcludableMultipleDictionaryFilter from "./ExcludableMultipleDictionaryFilter.vue";
import GroupedFilter from "./GroupedFilter.vue";
import IapRevenueFilter from "./IapRevenueFilter.vue";
import AdRevenueFilter from "./AdRevenueFilter.vue";
import SessionNumberFilter from "./SessionNumberFilter.vue";
import TrackerFilter from "./TrackerFilter.vue";
import RangeBasedMultipleEventParamFilter from "./RangeBasedMultipleEventParamFilter.vue";
import EventParamFilter from "./EventParamFilter.vue";
import BoolFilter from "./BoolFilter.vue";
import DatesFilter from "./DatesFilter.vue";
import EventParamDateFilter from "./EventParamDateFilter.vue";
import {
  FilterModel,
  FilterId,
  FILTER_ID_TO_COMPONENT,
  FILTER_ID_TO_INIT_FUNCTION,
  ValidationRule,
  FILTER_ID_TO_FILTER_PREVIEW_ID,
  FILTER_ID_TO_DATA_TYPES,
  COMMON_FILTER_ID_TO_ITEM_FILTER,
} from "@/shared/models";
import { VueAutocomplete } from "@/shared/types/ExtendedVueType";

@Component({
  components: {
    MultipleValueFilter,
    ExcludableMultipleDictionaryFilter,
    GroupedFilter,
    IapRevenueFilter,
    AdRevenueFilter,
    SessionNumberFilter,
    TrackerFilter,
    RangeBasedMultipleEventParamFilter,
    EventParamFilter,
    AttributionDateFilter,
    DatesFilter,
    BoolFilter,
    EventParamDateFilter,
  },
})
export default class Filters extends Vue {
  @Prop() applicationId!: string;
  @Prop() value!: Array<FilterModel>;
  @Prop() items!: Array<FilterId>;
  @Prop({ default: () => [] }) requiredItems!: Array<FilterId>;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) autofocus!: boolean;
  @Prop({ default: "top" }) buttonPosition!: string;
  @Prop() rules?: Array<ValidationRule>;
  @Prop({ default: () => [] }) errorMessages!: Array<string>;
  @Prop({ default: false }) isCalledFilters!: boolean;

  @Ref("filtersAutocomplete") readonly filtersAutocomplete!: VueAutocomplete;

  readonly filterIdToPreviewId = FILTER_ID_TO_FILTER_PREVIEW_ID;
  readonly filterIdToDataTypes = FILTER_ID_TO_DATA_TYPES;
  readonly filterIdToItemFilter = COMMON_FILTER_ID_TO_ITEM_FILTER;
  filterId: FilterId | null = null;
  isShowFilters = false;

  get filterIds() {
    return this.items
      .filter((id) => !this.value.some((it) => it.id === id))
      .filter((id) => !this.requiredItems.includes(id))
      .map((it) => {
        return {
          name: this.$lang(`shared.filters.${it.toString().toLowerCase()}`),
          value: it,
        };
      });
  }

  get filterComponents(): Record<string, string> {
    return FILTER_ID_TO_COMPONENT;
  }

  get isActivityKindType(): boolean {
    return this.value.some((value) => value.id === FilterId.ACTIVITY_KIND);
  }

  @Watch("value", { deep: true })
  private watchValue() {
    this.watchRequiredItems();
  }

  @Watch("requiredItems", { immediate: true })
  private watchRequiredItems() {
    if (this.readonly) {
      return;
    }

    this.requiredItems.forEach((it) => {
      const requiredFilter: FilterModel | undefined = this.value.find(
        (filter) => it === filter.id
      );

      if (!requiredFilter) {
        const filter = FILTER_ID_TO_INIT_FUNCTION[it]();
        filter.required = true;
        this.value.push(filter);
      } else if (!requiredFilter.required) {
        requiredFilter.required = true;
      }
    });
  }

  addFilter() {
    if (!this.filterId) {
      return;
    }

    this.value.unshift(FILTER_ID_TO_INIT_FUNCTION[this.filterId]());
    this.setIsShowFilters(false);
  }

  removeFilter(id: FilterId) {
    this.value.splice(
      this.value.findIndex((it) => it.id === id),
      1
    );
    this.filterId = null;
  }

  handleShowFilters() {
    this.setIsShowFilters(true);
  }

  setIsShowFilters(value: boolean) {
    this.isShowFilters = value;
    this.filtersAutocomplete.isFocused = value;
    this.filtersAutocomplete.isMenuActive = value;
  }
}
